import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
   // BrowserRouter,
   Route,
   RouterProvider,
   createBrowserRouter,
   createRoutesFromElements,
} from "react-router-dom";

const App = React.lazy(() => import("./App"));
const Home = React.lazy(() => import("./pages/home/home"));
const Menus = React.lazy(() => import("./pages/Menus/Menus"));
const BreakfastMenu = React.lazy(() => import("./pages/Menu/BreakfastMenu"));
const MainMenu = React.lazy(() => import("./pages/Menu/MainMenu"));
const BakeryMenu = React.lazy(() => import("./pages/Menu/BakeryMenu"));
const ShishaMenu = React.lazy(() => import("./pages/Menu/ShishaMenu"));
const Events = React.lazy(() => import("./pages/Events/Events"));
const HipHopParty = React.lazy(() => import("./pages/event/hip-hop-party"));
const JapanParty = React.lazy(() => import("./pages/event/japan-party"));
const PijamaParty = React.lazy(() => import("./pages/event/pijama-party"));
const BirthdayParty = React.lazy(() => import("./pages/event/birthday"));
const HipHopParty2 = React.lazy(() => import("./pages/event/hip-hop2"));
const ShishaParty = React.lazy(() => import("./pages/event/shisha-party"));

const router = createBrowserRouter(
   createRoutesFromElements(
      <Route path="/" element={<App />}>
         <Route index element={<Home />} />
         <Route path="menu" element={<Menus />} />
         <Route path="menu/breakfast" element={<BreakfastMenu />} />
         <Route path="menu/main" element={<MainMenu />} />
         <Route path="menu/bakery" element={<BakeryMenu />} />
         <Route path="menu/shisha" element={<ShishaMenu />} />
         <Route path="events/shisha" element={<ShishaParty />} />
         <Route path="events" element={<Events />} />
         <Route path="events/hip-hop-party" element={<HipHopParty />} />
         <Route path="events/hip-hop-06" element={<HipHopParty2 />} />
         <Route path="events/japan-party" element={<JapanParty />} />
         <Route path="events/pijama-party" element={<PijamaParty />} />
         <Route path="events/birthday" element={<BirthdayParty />} />
      </Route>
   )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
